const data = [
  {
    username: "무야호GB의 채팅방",
    intro: "나도 껴줘 ㅋㅋㅋ",
    time: "2023.10.26"
  },
  {
    username: "머신건의 채팅방",
    intro: "오늘 피시방 ㄱ?",
    time: "2023.10.25"
  },
  {
    username: "기르밍의 채팅방",
    intro: "기르미기르밍밍밍",
    time: "2023.10.11"
  },
  {
    username: "Haru와의 채팅방",
    intro: "오늘 몇시에 겜할래?",
    time: "2023.09.30"
  },
]

export default data
